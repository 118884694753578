













import { Vue, Component, Prop } from "vue-property-decorator";
import { projectStatusColor } from "@/entity/Project";

@Component
export default class ProjectCardBadge extends Vue {
  @Prop({ default: undefined }) activeStatus!: string;

  statusColor = projectStatusColor;
}





















































import { Vue, Component, Watch } from "vue-property-decorator";
import Project from "@/entity/Project";
import ProjectsService from "@/services/ProjectsService";
import ProjectStatuses from "@/components/home-view/ProjectStatuses.vue";
import ProjectCard from "@/components/home-view/ProjectCard.vue";
import { FilterBy } from "@/components/data-table/types";
import { State } from "vuex-class";
import User from "@/entity/User";
import { ALL_ITEMS_PER_PAGE } from "@/constants";

@Component({
  components: {
    ProjectStatuses,
    ProjectCard,
  },
})
export default class Projects extends Vue {
  projects: Project[] = [];
  showAll = false;
  totalProjects = 0;
  filterStatuses: string[] = [];
  isShowAllDisabled = false;
  showSkeleton = true;
  SAVED_STATUS = "SAVED_STATUS";
  HOME_PROJECTS_ALL = "HOME_PROJECTS_ALL";

  @State("user", { namespace: "auth" }) loggedInUser!: User;

  async fetchProjects(): Promise<void> {
    const filterBy: FilterBy = {
      consultantIds: (this.loggedInUser.id as number).toString(),
    };

    if (this.filterStatuses) {
      filterBy.status =
        this.filterStatuses.length > 0
          ? this.filterStatuses.toString()
          : undefined;
    }

    const response = await ProjectsService.find({
      itemsPerPage: ALL_ITEMS_PER_PAGE,
      filterBy,
      sortBy: {
        statusMarketing: false,
        historyUpdatedAt: true,
      },
    });
    this.projects = response.content;
    this.totalProjects = response.totalItems;
    this.isShowAllDisabled = response.totalItems <= this.projectsPerRow;
  }

  get projectsPerRow(): number {
    if (this.$vuetify.breakpoint.xsOnly) {
      return 1;
    } else if (this.$vuetify.breakpoint.smOnly) {
      return 2;
    } else if (this.$vuetify.breakpoint.mdOnly) {
      return 3;
    } else if (this.$vuetify.breakpoint.lgOnly) {
      return 4;
    } else {
      return 4;
    }
  }

  getProjectsInRow(row: number): Project[] {
    const offset = row * this.projectsPerRow - this.projectsPerRow;
    return this.projects.slice(offset, offset + this.projectsPerRow);
  }

  get rowNum(): number {
    return this.showAll
      ? Math.ceil(this.totalProjects / this.projectsPerRow)
      : 1;
  }

  get buttonText(): string {
    return this.showAll
      ? (this.$t("showLess") as string)
      : (this.$t("showAll") as string);
  }

  @Watch("filterStatuses")
  onFilterStatusesChange(): void {
    this.fetchProjects();
  }

  showMoreOrLess() {
    this.showAll = !this.showAll;
    window.localStorage.setItem(
      this.HOME_PROJECTS_ALL,
      this.showAll.toString()
    );
  }

  handleProjectStatusesChange(value: string[]): void {
    window.localStorage.setItem(this.SAVED_STATUS, JSON.stringify(value));
    this.filterStatuses = value;
  }

  async created(): Promise<void> {
    const savedStatus = window.localStorage.getItem(this.SAVED_STATUS);
    if (savedStatus !== null) {
      const parsedStatus = JSON.parse(savedStatus);
      if (parsedStatus.length > 0) {
        this.filterStatuses = parsedStatus;
      }
    }

    const showAll = window.localStorage.getItem(this.HOME_PROJECTS_ALL);
    if (showAll === "true") {
      this.showAll = true;
    }

    await this.fetchProjects();
    this.showSkeleton = false;
  }
}






















































































































import {
  Component,
  Prop,
  Mixins,
  Emit,
  PropSync,
} from "vue-property-decorator";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import Project from "@/entity/Project";
import Contact from "@/entity/Contact";
import ProjectsService from "@/services/ProjectsService";
import ContactsService from "@/services/ContactsService";
import { OptionItem } from "@/services/types";
import AsyncAutocomplete from "@/components/form/input/AsyncAutocomplete.vue";

@Component({
  components: {
    AsyncAutocomplete,
  },
})
export default class NotificationsFilters extends Mixins(
  TranslatedEnumListsMixin
) {
  @Prop({ required: true, type: Object }) filters!: any;
  @PropSync("sortBy", { default: "", type: String }) sortByValue!: string;
  @PropSync("sortType", { default: true, type: Boolean })
  sortTypeValue!: string;

  projects: Project[] = [];
  contacts: Contact[] = [];
  sortTypeOptions: OptionItem[] = [
    {
      name: this.$tc("asc", 1),
      value: false,
    },
    {
      name: this.$tc("desc", 1),
      value: true,
    },
  ];

  fetchProjects = ProjectsService.find;
  fetchContacts = ContactsService.find;

  onFilterChange(key: string, value: any) {
    this.filtersChanged({
      ...this.filters,
      [key]: value,
    });
  }

  @Emit()
  filtersChanged(filters: any) {
    return filters;
  }

  get sortingOptions(): OptionItem[] {
    return [
      {
        name: this.$tc("timeDate", 1),
        value: "createdAt",
      },
      {
        name: this.$tc("type", 1),
        value: "type",
      },
      {
        name: this.$tc("priorityLong", 1),
        value: "important",
      },
      {
        name: this.$tc("status", 1),
        value: "read",
      },
      {
        name: this.$tc("contact", 1),
        value: "contact.name",
      },
      {
        name: this.$tc("project", 1),
        value: "project.name",
      },
    ];
  }
}







































import { Vue, Component, Prop } from "vue-property-decorator";
import Project from "@/entity/Project";
import { getContactFullName } from "@/entity/Contact";
import ProjectCardBadge from "@/components/home-view/ProjectCardBadge.vue";
import moment from "moment";

@Component({
  components: {
    ProjectCardBadge,
  },
})
export default class ProjectCard extends Vue {
  @Prop({ required: true, type: Object }) project!: Project;

  get ownerName(): string {
    return getContactFullName(this.project.projectContact.contact);
  }

  navigateToProject(): void {
    this.$router.push({
      name: "projectDetail",
      params: {
        projectId: this.project.id?.toString() ?? "",
        projectType: this.project.projectType,
      },
    });
  }

  get formattedHistoryDate(): number | string {
    if (!this.project.historyUpdatedAt) {
      return "N/A";
    }

    const diff = moment().diff(
      moment.unix(this.project.historyUpdatedAt),
      "day"
    );
    return `${diff} ${this.$tc("dayAgo", diff === 1 ? 1 : 2)}`;
  }
}
























import { Component, Mixins, Prop } from "vue-property-decorator";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import { projectStatusColor } from "@/entity/Project";
import { State } from "vuex-class";
import User from "@/entity/User";
import ProjectsService, {
  ProjectCountByStatus,
} from "@/services/ProjectsService";

@Component
export default class ProjectStatuses extends Mixins(TranslatedEnumListsMixin) {
  @Prop({ required: true, type: Array }) value!: string[];

  statusColor = projectStatusColor;
  statuses: ProjectCountByStatus = {};

  @State("user", { namespace: "auth" }) loggedInUser!: User;

  async fetchProjectStatuses(): Promise<void> {
    this.statuses = await ProjectsService.countByStatus([
      this.loggedInUser.id as number,
    ]);
  }

  statusClick(status: string): void {
    const selectedStatusesNames = this.value;

    if (selectedStatusesNames.includes(status)) {
      const statusIndex = selectedStatusesNames.findIndex(
        (statusFromSelected) => status === statusFromSelected
      );
      selectedStatusesNames.splice(statusIndex, 1);
    } else {
      selectedStatusesNames.push(status);
    }

    this.$emit("change", selectedStatusesNames);
  }

  usedStatusesNames(): string[] {
    return Object.keys(this.statuses);
  }

  created() {
    this.fetchProjectStatuses();
  }
}

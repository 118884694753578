




























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ListResponseEntity } from "@/services/types";
import { TableQuery, FilterBy } from "@/components/data-table/types";
import { get, debounce } from "lodash";

@Component
export default class AsyncAutocomplete<T> extends Vue {
  @Prop({ required: true, type: [String, Array] }) searchBy!: string | [];
  @Prop({ default: false, type: Boolean }) multiple!: boolean;
  @Prop({ default: false, type: Boolean }) chips!: boolean;
  @Prop({ default: "name", type: String }) itemText!: string;
  @Prop({ default: "value", type: String }) itemValue!: string;
  @Prop({ required: true, type: Function })
  fetch!: (query: TableQuery) => Promise<ListResponseEntity<any>>;

  search: string | undefined | null = "";
  isLoading = false;
  items: T[] = [];
  internalValue: T | T[] | null = null;
  debounceFetch = debounce(this.fetchResults, 300);

  get filterBy(): FilterBy {
    const filterBy: FilterBy = {};

    if (!Array.isArray(this.searchBy)) {
      filterBy[this.searchBy] = this.search;
    } else {
      this.searchBy.forEach((searchBy) => {
        filterBy[searchBy] = this.search;
      });
    }

    return filterBy;
  }

  get optionsWithSelected(): T[] {
    if (this.internalValue === null) {
      return this.items;
    }

    if (this.multiple) {
      return this.items.concat(this.internalValue);
    } else {
      return [...this.items, this.internalValue as T];
    }
  }

  get isSearchEmpty(): boolean {
    return (
      this.search === undefined ||
      this.search === null ||
      this.search.length === 0
    );
  }

  async fetchResults(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.fetch({
        filterBy: this.filterBy,
      });
      this.items = response.content;
    } finally {
      this.isLoading = false;
    }
  }

  @Watch("internalValue")
  onInternalValueChange() {
    let toEmit;
    if (Array.isArray(this.internalValue)) {
      toEmit = this.internalValue.map((value) => {
        return get(value, this.itemValue);
      });
    } else {
      toEmit = get(this.internalValue, this.itemValue);
    }

    this.search = null;
    this.$emit("change", toEmit);
  }

  @Watch("search")
  onSearchChange() {
    if (this.isSearchEmpty) {
      this.items = [];
    } else {
      this.debounceFetch();
    }
  }
}












import { Component, Vue } from "vue-property-decorator";
import Projects from "@/components/home-view/Projects.vue";
import Notifications from "@/components/home-view/Notifications.vue";

@Component({
  components: {
    Projects,
    Notifications,
  },
})
export default class HomeView extends Vue {}































import { Vue, Component, Watch } from "vue-property-decorator";
import Notification from "@/entity/Notification";
import NotificationsService from "@/services/NotificationsService";
import NotificationsList from "@/components/notifications/NotificationsList.vue";
import NotificationsFilters from "@/components/home-view/NotificationsFilters.vue";
import User, { UserRole } from "@/entity/User";
import { State } from "vuex-class";

@Component({
  components: {
    NotificationsList,
    NotificationsFilters,
  },
})
export default class Notifications extends Vue {
  isLoading = false;
  showSkeleton = true;
  notifications: Notification[] = [];
  page = 1;
  totalPages = 0;
  itemsPerPage = 5;
  filters = {
    type: undefined,
    important: null,
    dismissed: false,
    projectIds: [],
    contactIds: [],
  };
  sortBy = "createdAt";
  sortType = true;

  @State("user", { namespace: "auth" }) user!: User;

  get hideActions(): boolean {
    return this.user.role !== UserRole.PROJECTS_ADMIN;
  }

  @Watch("filters", { deep: true })
  onFiltersChange(): void {
    this.fetchNotifications();
  }

  @Watch("page")
  onPageChange() {
    this.fetchNotifications();
  }

  @Watch("sortBy")
  onSortByChange() {
    this.fetchNotifications();
  }

  @Watch("sortType")
  onSortTypeChange() {
    this.fetchNotifications();
  }

  async fetchNotifications(preAction?: () => Promise<void>): Promise<void> {
    try {
      this.isLoading = true;

      if (preAction) {
        await preAction();
      }

      const sortBy = {
        [this.sortBy]: this.sortType,
      };

      if (this.sortBy === "contact.name") {
        sortBy["contact.firstName"] = this.sortType;
      }

      const response = await NotificationsService.find({
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        filterBy: {
          type: this.filters.type,
          important: this.filters.important,
          dismissed: this.filters.dismissed,
          projectIds:
            this.filters.projectIds.length > 0
              ? this.filters.projectIds.toString()
              : undefined,
          contactIds:
            this.filters.contactIds.length > 0
              ? this.filters.contactIds.toString()
              : undefined,
        },
        sortBy: sortBy,
      });
      this.notifications = response.content;
      this.totalPages = response.totalPages;
    } finally {
      this.isLoading = false;
    }
  }

  async getTotalNotifications(): Promise<number> {
    const response = await NotificationsService.find({
      page: 1,
      itemsPerPage: 0,
    });
    return response.totalItems;
  }

  onFiltersChanged(filters: any) {
    this.filters = filters;
  }

  async makeNotificationRead(id: number): Promise<void> {
    await this.fetchNotifications(async () => {
      await NotificationsService.dismiss([id], false);
    });
  }

  async makeNotificationUnread(id: number): Promise<void> {
    await this.fetchNotifications(async () => {
      await NotificationsService.dismiss([id], true);
    });
  }

  async makeNotificationImportant(id: number): Promise<void> {
    await this.fetchNotifications(async () => {
      await NotificationsService.important([id], false);
    });
  }

  async makeNotificationUnimportant(id: number): Promise<void> {
    await this.fetchNotifications(async () => {
      await NotificationsService.important([id], true);
    });
  }

  closeItself(): void {
    // destroy the vue listeners, etc
    this.$destroy();
    // remove the element from the DOM
    this.$el.parentNode?.removeChild(this.$el);
  }

  async created(): Promise<void> {
    await this.fetchNotifications();
    const totalNotifications = await this.getTotalNotifications();
    if (totalNotifications === 0) {
      this.closeItself();
    }

    this.showSkeleton = false;
  }
}
